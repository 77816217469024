import FirstScreen from "~/components/Plot/Components/FirstScreen";
import StrateReceiveEstimate from "~/components/Plot/Components/StrateReceiveEstimate";
import StrateEstimateSteps from "~/components/Plot/Components/StrateEstimateSteps";
import StratePlotCogedim from "~/components/Plot/Components/StratePlotCogedim";
import StrateKeyFigures from "~/components/Plot/Components/StrateKeyFigures";
import StrateSaleSteps from "~/components/Plot/Components/StrateSaleSteps";

import "./PlotPage.css";
import Plot from "../Forms/Plot";

export default function PlotPage() {
  return (
    <>
      <article class="page-plot">
        <FirstScreen />
        <StrateReceiveEstimate />
        <StrateEstimateSteps />
        <StratePlotCogedim />
        <StrateKeyFigures />
        <StrateSaleSteps />

        <section class="form-in-page-wrapper" id="plot-form">
          <div class="form-in-page form-in-page-white" data-test="form-in-page">
            <h2>Estimez votre bien en France métropolitaine gratuitement</h2>
            <div data-ga-zone="form">
              <Plot />
            </div>
          </div>
        </section>
      </article>
    </>
  );
}
